import styled from '@emotion/styled';
import { useCallback } from 'react';
import FlatIcon from '@/components/common/FlatIcon';
import ImageIcon from '@/components/common/ImageIcon';
import SingleButton from '@/components/common/buttons/SingleButton';
import useAppRouter from '@/hooks/useAppRouter';
import { GLOBAL_COLOR } from '@/styles/colors';
import { toRem } from '@/utils/commonUtils';

const Error404 = () => {
  const router = useAppRouter();

  const handleGoBackButtonClick = useCallback(() => {
    router.back();
  }, [router]);

  return (
    <>
      <HeaderContainer>
        <GoBackButton onClick={handleGoBackButtonClick}>
          <FlatIcon iconType={'icArrowLeft'} size={23} color={GLOBAL_COLOR.GRAY_900} />
        </GoBackButton>
      </HeaderContainer>
      <Container>
        <ImageIcon iconType={'icError'} size={80} />
        <Text>현재 페이지를 표시할 수 없어요.</Text>
        <ButtonContainer>
          <SingleButton buttonType={'default'} onClick={handleGoBackButtonClick}>
            이전 화면으로 이동
          </SingleButton>
        </ButtonContainer>
      </Container>
    </>
  );
};

export default Error404;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${toRem(120)};
`;

const Text = styled.p`
  font-weight: 400;
  font-size: ${toRem(16)};
  line-height: ${toRem(22)};
  margin: ${toRem(24)} 0;
  text-align: center;
  color: ${GLOBAL_COLOR.GRAY_500};
`;

const ButtonContainer = styled.div``;

const HeaderContainer = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${toRem(18)} ${toRem(16)};
  position: relative;
  height: ${toRem(58)};
  box-sizing: border-box;
  border: ${toRem(1)} solid ${GLOBAL_COLOR.GRAY_50};
`;

const GoBackButton = styled.button`
  position: absolute;
  left: ${toRem(20)};
`;
